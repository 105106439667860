
import {
    computed,
    createVNode
} from 'vue';
import {
    PlusOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    CalendarOutlined,
    EditOutlined
} from '@ant-design/icons-vue';
import Editor from './editor';

import {
    base64ToBlob
} from "@/utils/common"

export default {
    name: "CostRecord",
    components: {
        DownloadOutlined,
        PlusOutlined,
        CalendarOutlined,
        EditOutlined,
        Editor
    },
    provide() {
        return {
            supplier_list: computed(() => this.supplier_list)
        }
    },
    data() {
        return {
            url: '/admin/cost_record/list',
            // 表格搜索条件
            where: {
                master_tracking_number: ""
            },
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 供应商列表
            supplier_list: [],
            // 导出加载
            exportLoading: false,
            // 状态
            status: {
                0: "待核销",
                1: "未对账",
                2: "已核销",
                3: "已核销"
            },
            // 总利润
            totalprofit: 0,
            // 打开批量主单查询
            showMoreSelect: false,
            // 重构查询语句
            renderWhereNumber: ""
        };
    },
    computed: {
        tracking_number_list: {
            get() {
                if (Array.isArray(this.where.master_tracking_number)) {
                    return this.where.master_tracking_number;
                }
                return this.where.master_tracking_number ? this.where.master_tracking_number.split(',') : [];
            },
            set(val) {
                this.where.master_tracking_number = val.join(',');
            }
        },
        columns() {
            return [{
                    key: 'index',
                    width: 68,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t('会员代号'),
                    dataIndex: 'user_account_code',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('订单号'),
                    dataIndex: 'order_po_number',
                    width: 200,
                    sorter: true
                },
                {
                    title: this.$t('主单号'),
                    dataIndex: 'master_tracking_number',
                    width: 200,
                    sorter: true
                },
                {
                    title: this.$t('供应商'),
                    dataIndex: 'supplier_txt',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('费用名称'),
                    dataIndex: 'charge_name',
                    width: 150,
                    sorter: true,
                    ellipsis: true
                },
                {
                    title: this.$t('费用'),
                    dataIndex: 'charge',
                    width: 150,
                    sorter: true,
                    slots: {
                        customRender: "charge"
                    }
                },
                {
                    title: this.$t('利润'),
                    dataIndex: 'profit',
                    width: 150,
                    sorter: true,
                    customRender: ({
                        text
                    }) => {
                        return `${text} ${this.$store.state.system.config.currency}`
                    }
                },
                {
                    title: this.$t('备注'),
                    dataIndex: 'remark',
                    sorter: true
                },
                {
                    title: this.$t('状态'),
                    dataIndex: 'status',
                    width: 80,
                    algin: "center",
                    sorter: true,
                    slots: {
                        customRender: "status"
                    }
                },
                {
                    title: this.$t('创建时间'),
                    dataIndex: 'created',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('更新时间'),
                    dataIndex: 'audit_time',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('操作'),
                    key: 'action',
                    width: 100,
                    slots: {
                        customRender: 'action'
                    }
                }
            ]
        },
        selection_filter() {
            return this.selection.filter(i => i.status == 0)
        },
        statusOption() {
            const arr = Object.keys(this.status);
            return arr.filter(k => k != 3).map(key => {
                return {
                    label: this.status[key],
                    value: key
                }
            })
        },
    },
    methods: {
        // 保存查询条件
        saveWhere(){
            this.tracking_number_list = this.renderWhereNumber.split(/\n|，|,| /);
            this.showMoreSelect = false;
        },
        // 打开
        openShowSelect(){
            this.renderWhereNumber = this.tracking_number_list.join("\n")
            this.showMoreSelect = true;
        },
        // 数据源
        parseData(data) {
            this.totalprofit = data.totalprofit
            return data
        },
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showEdit = true;
        },
        /**
         * 获取供应商
         */
        getSupplier() {
            this.$http.get("/admin/supplier/list", {
                params: {
                    limit: 999,
                    page: 1
                }
            }).then(res => {
                if (res.data.code === 0) {
                    this.supplier_list = res.data.data;
                }
            })
        },
        /**
         * 导出Excel
         */
        exportExcel() {
            this.exportLoading = true;

            this.$http
                .get(`/admin/cost_record/export`, {
                    params: this.where
                })
                .then(res => {
                    this.exportLoading = false;

                    if (res.data.code === 0) {
                        const blob = base64ToBlob(
                            res.data.data,
                            "application/vnd.ms-excel"
                        );
                        const fileName = `Charges-${new Date().getTime()}.xls`;

                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const el = document.createElement("a");

                            el.download = fileName;
                            el.style.display = "none";
                            el.href = URL.createObjectURL(blob);
                            document.body.appendChild(el);

                            el.click();

                            URL.revokeObjectURL(el.href);
                            document.body.removeChild(el);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    this.exportLoading = false;
                    this.$message.error(e.message);
                });
        },
        /**
         * 核销
         */
        confirmRecord(row) {
            const hide = this.$message.loading('请求中...', 0);

            this.$http.post('/admin/cost_record/writeoff', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量核销 */
        confirmBatch() {
            if (!this.selection_filter.length) {
                return this.$message.error('请至少选择一条数据');
            }

            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要核销选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('/admin/cost_record/writeoff', {
                        ids: this.selection_filter.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('请求中...', 0);

            this.$http.post('/admin/cost_record/delete', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error('请至少选择一条数据');
            }

            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要删除选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('/admin/cost_record/delete', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /* 更改下拉选择 */
        handleChangeTN(arr) {
            const length = arr.length;
            if (length <= 0) return
            const val = arr[length - 1];

            if (val.indexOf(' ') > -1) {
                arr.splice(length - 1, 1);
                arr.push(...val.split(' '))
                this.tracking_number_list = arr;
            }
        },
        /* 粘贴动作 */
        handlePasting(e) {
            this.where.master_tracking_number = [...e.clipboardData.getData('text').split(/[\r\n]|[,]|[ ]|[，]|[、]/).filter(item => item), ...this.tracking_number_list];
        },
    },
    mounted() {
        this.getSupplier();
    },
    activated() {
        this.getSupplier();
    }
}
